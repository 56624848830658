// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-drawable-graph-index-js": () => import("./../src/pages/app/drawable-graph/index.js" /* webpackChunkName: "component---src-pages-app-drawable-graph-index-js" */),
  "component---src-pages-app-games-index-js": () => import("./../src/pages/app/games/index.js" /* webpackChunkName: "component---src-pages-app-games-index-js" */),
  "component---src-pages-app-graph-search-index-js": () => import("./../src/pages/app/graph-search/index.js" /* webpackChunkName: "component---src-pages-app-graph-search-index-js" */),
  "component---src-pages-app-index-js": () => import("./../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-nepali-date-converter-js": () => import("./../src/pages/app/nepali-date/converter.js" /* webpackChunkName: "component---src-pages-app-nepali-date-converter-js" */),
  "component---src-pages-app-nepali-date-index-js": () => import("./../src/pages/app/nepali-date/index.js" /* webpackChunkName: "component---src-pages-app-nepali-date-index-js" */),
  "component---src-pages-app-sorting-index-js": () => import("./../src/pages/app/sorting/index.js" /* webpackChunkName: "component---src-pages-app-sorting-index-js" */),
  "component---src-pages-app-wasm-image-to-ascii-js": () => import("./../src/pages/app/wasm/image-to-ascii.js" /* webpackChunkName: "component---src-pages-app-wasm-image-to-ascii-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

